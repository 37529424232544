@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
* {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
}

.banner-btn {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    border-radius: 0.3rem;
    font-size: 0.8rem;
    border: 2px solid #077cb6;
    cursor: pointer;
}

.banner-btn-container a,
.banner-btn-container a:hover {
    color: #fff;
    text-decoration: none;
}

.banner-btn-container {
    display: flex;
}

.b-btn-1 {
    margin-left: 0;
    background-color: #0888c9;
}

.b-btn-1:hover {
    background-color: #086c9f;
}

.b-btn-2 {
    /* background-color: #0888c9; */
}

.b-btn-2:hover {
    background-color: #086c9f;
}

.about-img img {
    width: 80%;
    height: auto;
}


/* .section2 h2,
.section3 h2 {
    font-size: 2.8rem;
    font-weight: 600;
} */

.section4 {
    background: rgb(52, 207, 150);
    background: linear-gradient(342deg, rgba(52, 207, 150, 1) 0%, rgba(50, 211, 181, 1) 12%, rgba(8, 153, 213, 1) 100%);
    color: #fff;
}

.feature-item {
    border: 3px solid #2ed3c9;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 3px 3px 10px 1px #70ebbbe6;
    transition: 0.5s;
    cursor: pointer;
    min-height: 13.7rem;
}

.container-bg {
    background-color: #d7ffef;
}

.container-bg-2 {
    background-color: #dbf4ff;
}

.subscription-div {
    border: 4px solid;
    padding: 0 5px;
    border-radius: 100px;
}

.subscription-div input::placeholder {
    color: #fff;
    font-size: 1rem;
    text-transform: capitalize;
}

.subscription-div input {
    background: transparent;
    border: unset;
}

.subscription-div button {
    border: unset;
    border-radius: 100px;
    padding: 0.5rem 1.3rem;
    margin: 0.3rem 0;
    background: #0888c9;
    color: #fff;
    font-weight: 600;
}

.contact-box {
    border: 2px solid #33d3b2;
    padding: 1.5rem;
    border-radius: 15px;
    box-shadow: 3px 3px 10px 1px #70ebbbe6;
}

.btn-gradient {
    background: rgb(52, 207, 150);
    background: linear-gradient(342deg, rgba(52, 207, 150, 1) 0%, rgba(50, 211, 181, 1) 12%, rgba(8, 153, 213, 1) 100%);
    border: 0 !important;
    padding: 0.7rem 2rem !important;
}

span.required {
    color: red;
    font-weight: bold;
}

.slider-container h1 {
    font-size: 4rem;
}

.slider-container p {
    font-size: 1.4rem;
}

.sectionTitle {
    font-size: 3rem;
    font-weight: 600;
    text-transform: uppercase;
}

.section2 p {
    font-size: 1.2rem;
}

.section4 h6 {
    font-size: 1.3rem;
}

.feature-item:hover {
    background: #c3eeff;
    box-shadow: 3px 3px 10px 1px #98dbf5;
    border-color: #8edfff;
}

.teacher-item {
    text-align: center;
}

.teacher-item img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid #33d3b2;
    padding: 5px;
}

.subscription-div button {
    border-radius: 100px !important;
}
.subscription-div input.form-control:focus {
    background: transparent;
    box-shadow: none;
    color: #fff;
}
@media (max-width: 768px) {
    .container.subscription-container h6 > br {
        display: none;
    }
    .subscription-div{
        border: none;
    }
    .subscription-div .input-group input {
        width: 100%;
        border: 2px solid #fff;
        margin-bottom: 0.5rem;
        border-radius: 50px !important;
    }
    
    .subscription-div .input-group {
        flex-direction: column;
    }

    .slider-container {
        text-align: center;
    }
    .slider-container .col-sm-6.text-center>div {
        transform: none !important;
    }
    .banner-btn-container a {
        margin: 0.5rem 0;
    }
    .banner-btn-container a>div {
        margin: 0;
    }
    .banner-btn-container {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .slider-container p {
        font-size: 1.1rem;
    }
    .slider-container h1 {
        font-size: 2.5rem;
    }
    .slider-container img {
        height: 45vh !important;
        margin-top: 1rem;
    }
    section.p-5 {
        padding: 3rem 1rem !important;
    }
    .sectionTitle {
        font-size: 2rem;
        font-weight: 700;
    }
    .about-img img {
        width: 100%;
        box-shadow: 1px 2px 8px 3px #c5f0ff;
        border-radius: 10px;
    }
    footer.text-center .row div {
        text-align: center !important;
        margin: 0.5rem 0;
    }
    .container.subscription-container h6 {
        font-size: 1rem;
        font-weight: 400;
    }
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -7px;
}